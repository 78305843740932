<template>
  <b-row>
    <b-col cols="12">
      <b-overlay
        :show="$apollo.loading"
      >
        <b-card title="All Programs">

          <!-- search input -->
          <div class="custom-search d-flex justify-content-start">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">Search</label>
                <b-form-input
                  v-model="searchTerm"
                  class="d-inline-block mr-1"
                  placeholder="Search"
                  type="text"
                />
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  :to="{name: 'add-programs'}"
                  class="d-inline-flex mr-1"
                  variant="outline-primary"
                >
                  <feather-icon
                    class="mr-50"
                    icon="PlusIcon"
                  />
                  <span class="align-middle text-nowrap">Add Program</span>
                </b-button>
              </div>
            </b-form-group>
          </div>
          <!-- table -->
          <vue-good-table
            :columns="columns"
            :pagination-options="{
              enabled: true,
              perPage:pageLength
            }"
            :rows="rows"
            :rtl="direction"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
          >

            <template
              slot="table-row"
              slot-scope="props"
            >

              <!-- Column: Name -->
              <span
                v-if="props.column.field === 'fullName'"
                class="text-nowrap"
              >
                <b-avatar
                  :src="props.row.avatar"
                  class="mx-1"
                />
                <span class="text-nowrap">{{ props.row.fullName }}</span>
              </span>

              <!-- Column: Status -->
              <span
                v-else-if="props.column.field === 'status'"
                class="d-flex justify-content-between align-items-center"
              >
                <b-badge
                  :variant="statusVariant(props.row.status)"
                  class="border"
                >
                  {{ props.row.status }}
                </b-badge>
                <b-dropdown
                  size="sm"
                  toggle-class="text-decoration-none"
                  variant="link"
                  no-caret
                >
                  <template v-slot:button-content>
                    <span class="align-middle text-nowrap">Update Status<feather-icon
                      class="align-middle ml-25"
                      icon="ChevronDownIcon"
                    /></span>
                  </template>
                  <b-dropdown-item
                    v-if="props.row.status === 'draft'"
                    :disabled="props.row.status === 'scheduled'"
                    @click="updateProgramStatus(props.row.id, 'scheduled')"
                    variant="primary"
                  >
                    <feather-icon
                      class="mr-50"
                      icon="ClockIcon"
                    />
                    <span>Schedule Program</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    variant="success"
                    :disabled="props.row.status === 'complete'"
                    @click="updateProgramStatus(props.row.id, 'complete')"
                  >
                    <feather-icon
                      class="mr-50"
                      icon="CheckCircleIcon"
                    />
                    <span>Mark as Complete</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    variant="danger"
                    :disabled="props.row.status === 'discontinued'"
                    @click="updateProgramStatus(props.row.id, 'discontinued')"
                  >
                    <feather-icon
                      class="mr-50"
                      icon="StopCircleIcon"
                    />
                    <span>Discontinue Program</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>

              <span v-else-if="props.column.field === 'buttons'">
                <b-button
                  variant="outline-primary"
                  class="mr-50"
                  size="sm"
                  :to="{name: 'program-manager', params: {id: props.row.id}}"
                >Program Manager</b-button>
                <b-button
                  variant="outline-primary"
                  class="mr-50"
                  size="sm"
                  :to="{name: 'application-manager', params: {id: props.row.id}}"
                >Application Manager</b-button>
                <b-button
                  variant="link"
                  size="sm"
                  _target="_blank"
                  :to="{name: 'view-programs', params: {
                    id:props.row.id
                  }}"
                >View Program
                </b-button>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap ">
                    Showing 1 to
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3','5','10']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> of {{ props.total }} entries </span>
                </div>
                <div>
                  <b-pagination
                    :per-page="pageLength"
                    :total-rows="props.total"
                    :value="1"
                    class="mt-1 mb-0"
                    first-number
                    last-number
                    next-class="next-item"
                    prev-class="prev-item"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-card>
      </b-overlay>
    </b-col>
  </b-row>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BPagination,
  BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store'
import gql from 'graphql-tag'
import { getUserData } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,

  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pageLength: 5,
      dir: false,
      filter: {
        status: 'Current',
      },
      columns: [
        {
          label: 'Title',
          field: 'title',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: '',
          field: 'buttons',
          sortable: false,
        },

      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        scheduled: 'light-primary',
        discontinued: 'light-danger',
        draft: 'light-secondary',
        complete: 'light-success',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    userOrgs() {
      const arr = getUserData()
        .associatedOrgDetails
      return arr.map(e => e.organization_id)
        .filter(obj => obj) // Remove nulls
    },
  },
  methods: {
    // GraphQL Mutate update program status as discontinued
    updateProgramStatus(id, status) {
      this.$apollo.mutate({
        mutation: gql`
          mutation {
             update_programs_basicinfo_by_pk(pk_columns: {id: ${id}}, _set: {status: ${status}}) {
              id
              status
            }
          }`,
        update: () => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Status Updated Successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$apollo.queries.rows.refetch()
          this.mutationLoading = false
        },
      })
      // .then(({ data }) => {
      //   this.$toast({
      //     component: ToastificationContent,
      //     props: {
      //       title: `Program status updated to ${data.updateProgramStatus.status}`,
      //       icon: 'CheckIcon',
      //       variant: 'success',
      //     },
      //   })
      //   this.$apollo.queries.rows.refetch()
      // })
      // .catch(() => {
      //   this.$toast({
      //     component: ToastificationContent,
      //     props: {
      //       title: 'Error updating program status',
      //       icon: 'ErrorIcon',
      //       variant: 'danger',
      //     },
      //   })
      // })
    },

  },
  apollo: {
    rows: {
      query() {
        return gql`
        query MyQuery @cached {
        programs_basicinfo(order_by: {date_created: desc}, where: {organization_id_id: {_in: [${this.userOrgs}]}, type: {_in: ["Pre-incubation Program", "Incubation Program", "Accelerator Program"]}}) {
          id,
          title,
          status,
          programs_startupparticipants
          {
            programs_applicantstable {
              users_organizationtable {
                title
              }
            }
          }
          users_associationtables {
            users_customuser {
              full_name
            }
          }
        }
      }`
      },
      update: data => data.programs_basicinfo,
    },
  },
}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
